import type { ITrackingByBookmarkContent, ITrackingByBookmarkContentList, ITrackingByFollowContent, ITrackingBySelectContent, ITrackingByUploadContent, ITrackingByViewContent } from '../types/tracking'
import { useTrackingGA } from '~/modules/tracking/ga/tracking-ga'

export default function useContentTracking () {
  const { addDataLayerWithEvent, GA_EVENT_NAME } = useTrackingGA()

  /**
   * 콘텐츠 선택
   * @param data
   */
  const trackingBySelectContent = (data: ITrackingBySelectContent) => {
    addDataLayerWithEvent(GA_EVENT_NAME.SELECT_CONTENT, {
      content_id: data.postSeq,
      content_name: data.title,
      category1: data.categoryNo,
      category2: data.categoryNo2,
      corpname: data.companyName
    })
  }

  /**
   * 콘텐츠 목록 조회
   */
  const trackingByViewContentList = () => {
    addDataLayerWithEvent(GA_EVENT_NAME.VIEW_CONTENT_LIST, {})
  }

  /**
   * 콘텐츠 조회
   * @param data
   */
  const trackingByViewContent = (data: ITrackingByViewContent) => {
    addDataLayerWithEvent(GA_EVENT_NAME.VIEW_CONTENT, {
      content_id: data.postSeq,
      content_name: data.title,
      category1: data.categoryNo,
      category2: data.categoryNo2,
      corpname: data.companyName
    })
  }

  /**
   * 콘텐츠 상세 팔로우
   * @param data
   */
  const trackingByFollowContent = (data: ITrackingByFollowContent) => {
    addDataLayerWithEvent(GA_EVENT_NAME.FOLLOW_CONTENT, {
      content_id: data.postSeq,
      content_name: data.title,
      category1: data.categoryNo,
      category2: data.categoryNo2,
      corpname: data.companyName
    })
  }

  /**
   * 콘텐츠 목록 북마크
   * @param data
   */
  const trackingByBookmarkContentList = (data: ITrackingByBookmarkContentList) => {
    addDataLayerWithEvent(GA_EVENT_NAME.BOOKMART_CONTENT_LIST, {
      content_id: data.postSeq,
      content_name: data.title,
      category1: data.categoryNo,
      category2: data.categoryNo2,
      corpname: data.companyName
    })
  }

  /**
   * 콘텐츠 상세 북마크
   * @param data
   */
  const trackingByBookmarkContent = (data: ITrackingByBookmarkContent) => {
    addDataLayerWithEvent(GA_EVENT_NAME.BOOKMART_CONTENT, {
      content_id: data.postSeq,
      content_name: data.title,
      category1: data.categoryNo,
      category2: data.categoryNo2,
      corpname: data.companyName
    })
  }

  /**
   * 콘텐츠 상세 공유
   */
  const trackingByShareContent = () => {
    addDataLayerWithEvent(GA_EVENT_NAME.BOOKMART_CONTENT, {})
  }

  /**
   * 콘텐츠 업로드
   * @param data
   */
  const trackingByUploadContent = async (data: ITrackingByUploadContent) => {
    addDataLayerWithEvent(GA_EVENT_NAME.UPLOAD_CONTENT, {
      content_id: data.postSeq,
      content_name: data.title,
      category1: data.categoryNo,
      category2: data.categoryNo2
      // corpname: profile.
    })
  }

  /**
   * 콘텐츠 임시 저장
   */
  const trackingByTemporaryContent = () => {
    addDataLayerWithEvent(GA_EVENT_NAME.TEMPORARY_CONTENT, {})
  }

  return {
    trackingBySelectContent,
    trackingByViewContentList,
    trackingByViewContent,
    trackingByFollowContent,
    trackingByBookmarkContentList,
    trackingByBookmarkContent,
    trackingByShareContent,
    trackingByUploadContent,
    trackingByTemporaryContent
  }
}
